<template>
  <div class="multiClassroom">
    <OtherBanner :banner-list="bannerListPc" :banner-list-mobile="bannerListMobile" />
    <div class="one-container-pc">
      <div class="center-top-box">
        <p class="title">
          方案概述
        </p>
        <p class="sub-title">
          融合教学互动、教学管理为一体的高度集成化学习环境
        </p>
        <div class="one-remark">
          <span class="left-mark icon-size" />
          <div class="info">
            集成新一代信息技术，依托智能化、多类型、多功能的智慧教学设备，打造集教学管理、交流研讨、多屏互动、成果展示、多元评价等功能于一体的智慧教学环境，满足创新型人才培养的课堂教学需求。教室桌椅布局灵活多样，可快速分组形成适合研讨的教学空间，支持多场景教学，实现创新课堂。
          </div>
          <span class="right-mark icon-size" />
        </div>
      </div>
      <MultiBanner />
    </div>
    <div class="two-container-pc">
      <div class="two-title">
        核心功能
      </div>
      <div class="two-list">
        <div v-for="(item,index) in twoList" :key="index" class="item">
          <img :src="item.imgUrl" class="twoImg">
          <p class="two-type">
            {{ item.title }}
          </p>
          <div class="two-remark">
            {{ item.remark }}
          </div>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="two-container-mobile">
      <div class="two-title">
        核心功能
      </div>
      <div class="two-list">
        <div v-for="(item,index) in twoList" :key="index" class="item">
          <img :src="item.imgUrl" class="twoImg">
          <div class="right">
            <p class="two-type">
              {{ item.title }}
            </p>
            <div class="two-remark">
              {{ item.remark }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="three-container-pc">
      <span class="top-left-corner icon-ellipsis" />
      <div class="three-title">
        应用场景
      </div>
      <div class="center-context-box">
        <img src="../../assets/images/room/icon-room1.jpg" class="bigImg">
        <div class="center-context-info">
          <div class="three-type">
            <img src="../../assets/images/room/icon-three-ing1.png" class="smallImg">
            <label>研讨教室</label>
            <p class="sub-type">
              分组研讨、小组投屏、动态批注、视频录制、成果讲评，开放互动，协作探究。
            </p>
          </div>
        </div>
      </div>
      <span class="bottom-right-corner icon-ellipsis" />
    </div>
    <div class="three-container-pc fourth-container-pc">
      <div class="center-context-box">
        <div class="center-context-info">
          <div class="three-type">
            <img src="../../assets/images/room/icon-three-ing2.png" class="smallImg">
            <label>虚拟仿真实验教室</label>
            <p class="sub-type">
              3D、AR等技术支撑学习情景，提高实验教学的动态互动。
            </p>
          </div>
        </div>
        <img src="../../assets/images/room/icon-room2.jpg" class="bigImg">
      </div>
    </div>
    <div class="three-container-pc five-container-pc">
      <span class="top-left-corner icon-ellipsis" />
      <div class="center-context-box">
        <img src="../../assets/images/room/icon-room3.jpg" class="bigImg">
        <div class="center-context-info">
          <div class="three-type">
            <img src="../../assets/images/room/icon-three-ing3.png" class="smallImg">
            <label>3D编程教室</label>
            <p class="sub-type">
              通过界面交互或编程课程，锻炼学生逻辑思维。
            </p>
          </div>
        </div>
      </div>
      <span class="bottom-right-corner icon-ellipsis" />
    </div>
    <div class="three-container-pc fourth-container-pc six-container-pc">
      <div class="center-context-box">
        <div class="center-context-info">
          <div class="three-type">
            <img src="../../assets/images/room/icon-three-ing4.png" class="smallImg">
            <label>书法教室</label>
            <p class="sub-type">
              引入数字交互技术，创新书法文化教学。
            </p>
          </div>
        </div>
        <img src="../../assets/images/room/icon-room4.jpg" class="bigImg">
      </div>
    </div>
    <!-- mobile -->
    <div class="three-container-mobile">
      <div class="three-title">
        应用场景
      </div>
      <div v-for="(item,index) in threeList" :key="index" class="center-context-box">
        <img :src="item.imgUrl" class="bigImg">
        <div class="three-type">
          <label>{{ item.title }}</label>
          <p class="sub-type">
            {{ item.subTitle }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MultiBanner from '@/components/baseComon/multibanner.vue';
import OtherBanner from '@/components/baseComon/otherBanner.vue';

// 菜单数据
export default {
  name: 'MultiClassroom',
  components: { MultiBanner, OtherBanner },
  data() {
    return {
      bannerListPc: [
        {
          imgUrl: require('../../assets/images/room/room-banner1.png')
        },
        {
          imgUrl: require('../../assets/images/room/room-banner2.png')
        },
        {
          imgUrl: require('../../assets/images/room/room-banner3.png')
        },
        {
          imgUrl: require('../../assets/images/room/room-banner4.png')
        }
      ],
      bannerListMobile: [
        { imgUrl: require('../../assets/images/room/H5banner1.png') },
        { imgUrl: require('../../assets/images/room/H5banner2.png') },
        { imgUrl: require('../../assets/images/room/H5banner3.png') },
        { imgUrl: require('../../assets/images/room/H5banner4.png') }
      ],
      twoList: [
        {
          imgUrl: require('../../assets/images/room/icon-two-img1.png'),
          title: '多屏联动',
          remark: '个人见解可投至小组屏教师端实时广播小组多屏同显信息'
        },
        {
          imgUrl: require('../../assets/images/room/icon-two-img2.png'),
          title: '多终端接入',
          remark: '网络连接、热点发射互不干扰支持≥56个终端同时接入'
        },
        {
          imgUrl: require('../../assets/images/room/icon-two-img3.png'),
          title: '全员参与互动',
          remark: '头脑风暴，标注编辑观点协作书写支持多人同步输入讨论结果一键发送至学生端'
        },
        {
          imgUrl: require('../../assets/images/room/icon-two-img4.png'),
          title: '多场景教学',
          remark: '支持多种教学场景，如小组研讨、仿真实验课堂、3D编程课堂、书法课堂等'
        },
        {
          imgUrl: require('../../assets/images/room/icon-two-img5.png'),
          title: '大小同屏',
          remark: '智能黑板搭配智能中屏、智慧黑板，同步控制大屏画面快捷键简化多种操作'
        }
      ],
      threeList: [
        {
          imgUrl: require('../../assets/images/room/icon-room1.jpg'),
          title: '研讨教室',
          subTitle: '分组研讨、小组投屏、动态批注、视频录制、成果讲评，开放互动，协作探究。'
        },
        {
          imgUrl: require('../../assets/images/room/icon-room2.jpg'),
          title: '虚拟仿真实验教室',
          subTitle: '3D、AR等技术支撑学习情景，提高实验教学的动态互动。'
        },
        {
          imgUrl: require('../../assets/images/room/icon-room3.jpg'),
          title: '3D编程教室',
          subTitle: '通过界面交互或编程课程，锻炼学生逻辑思维。'
        },
        {
          imgUrl: require('../../assets/images/room/icon-room4.jpg'),
          title: '书法教室',
          subTitle: '引入数字交互技术，创新书法文化教学。'
        }
      ]
    };
  }
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .multiClassroom{
    .one-container-pc{
      position: relative;
      background: #fff;
      .center-top-box{
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        .title{
          font-size: 44px;
          font-weight: bold;
          color: #333333;
          padding-top: 60px;
        }
        .sub-title{
          font-size: 24px;
          font-weight: 400;
          color: #FD9C02;
          line-height: 28px;
        }
        .one-remark{
          margin-top: 25px;
          background: #F5F7FA;
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          padding: 40px 50px;
          text-align: left;
          position: relative;
          .icon-size{
            display: block;
            width: 40px;
            height: 35px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            &.left-mark{
              background-image: url('../../assets/images/icon-left-mark.png');
              top: -10px;
              left: 35px;
            }
            &.right-mark{
              background-image: url('../../assets/images/icon-right-mark.png');
              bottom: -10px;
              right: 30px;
            }
          }
          .info{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            line-height: 34px;
          }
        }
      }
    }
    .two-container-pc{
      background: #F5F7FA;
      padding: 80px 80px 100px;
      .two-title{
        font-size: 44px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 60px;
        text-align: center;
      }
      .two-list{
        display: flex;
        justify-content: space-around;
        .item{
          width: 352px;
          height: 230px;
          text-align: center;
          padding: 0 60px;
          &:hover {
            transform: scale(1.1);
          }
          .two-type{
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            margin: 16px 0;
          }
          .two-remark{
            font-size: 16px;
            font-weight: 400;
            color: #666666;
          }
          .twoImg{
            width: 88px;
            height: 88px;
          }
        }
      }
    }
    .two-container-mobile{
      display: none;
    }
    .three-container-pc{
      background: #fff;
      position: relative;
      .three-title{
        font-size: 44px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        padding: 80px 0 50px;
      }
      .center-context-box{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 90px;
        .bigImg{
          width: 585px;
          height: 320px;
        }
        .smallImg{
          width: 68px;
          height: 68px;
          margin-right: 20px;
        }
        .three-type{
          margin-left: 80px;
          label{
            font-size: 36px;
            font-weight: 400;
            color: #333333;
            vertical-align: middle;
          }
        }
        .sub-type{
          font-size: 22px;
          font-weight: 400;
          color: #999999;
          margin-top: 20px;
        }
      }
      .top-left-corner{
        position: absolute;
        left: 0;
        top: 0px;
      }
      .bottom-right-corner{
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .fourth-container-pc{
      background: #F5F7FA;
      padding-top: 90px;
      .three-type{
        margin-right: 80px;
      }
      .sub-type{
        width: 400px;
      }
    }
    .five-container-pc{
      padding-top: 90px;
    }
    .three-container-mobile{
      display: none;
    }
  }
}
@media screen and (min-width:1200px) and (max-width: 1400px){
  .multiClassroom .two-container-pc{
    padding: 10px 10px 100px;
  }
  .multiClassroom .two-container-pc .two-list .item {
    padding: 0 30px !important;
  }
}
@media screen and (min-width:992px) and (max-width: 1200px){
  .multiClassroom .two-container-mobile .two-list .item{
    width: 45% !important;
    height: 130px !important;
  }
  .multiClassroom .three-container-mobile .center-context-box .bigImg{
    height: 550px !important;
  }
}
@media screen and (max-width: 1200px) {
.multiClassroom{
    .one-container-pc{
      position: relative;
      background: #fff;
      .center-top-box{
        text-align: center;
        padding: 15px;
        .title{
          font-size: 23px;
          font-weight: bold;
          color: #333333;
          padding-top: 25px;
        }
        .sub-title{
          font-size: 13px;
          font-weight: 400;
          color: #FD9C02;
          line-height: 22px;
        }
        .one-remark{
          margin-top: 25px;
          background: #F5F7FA;
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          padding: 15px;
          text-align: left;
          position: relative;
          .icon-size{
            display: block;
            width: 20px;
            height: 17px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            &.left-mark{
              background-image: url('../../assets/images/icon-left-mark.png');
              top: -10px;
              left: 35px;
            }
            &.right-mark{
              background-image: url('../../assets/images/icon-right-mark.png');
              bottom: -10px;
              right: 30px;
            }
          }
          .info{
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
    .two-container-pc{
      display: none;
    }
    .two-container-mobile{
      .two-title{
        font-size: 23px;
        font-weight: bold;
        color: #333333;
        margin: 25px 0 10px;
        text-align: center;
      }
      .two-list{
        display: flex;
        flex-wrap: wrap;
        .item{
          padding: 30px;
          display: flex;
          align-items: center;
          margin: 15px 15px 0;
          background: #fff;
          border-radius: 10px;
          .two-type{
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
          .two-remark{
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          }
          .twoImg{
            width: 64px;
            height: 64px;
            margin-right: 25px;
          }
        }
      }
    }
    .three-container-pc{
      display: none;
    }
    .three-container-mobile{
      .three-title{
        font-size: 23px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin: 25px 0 25px;
      }
      .center-context-box{
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        margin: 15px;
        .bigImg{
          width: 100%;
          height: 167px;
        }
        .three-type{
          margin-top: 20px;
          label{
            font-size: 19px;
            font-weight: bold;
            color: #333333;
          }
          .sub-type{
            font-size: 13px;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
