<!--
 * @Author: your name
 * @Date: 2020-05-28 10:17:46
 * @LastEditTime: 2021-01-06 20:00:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/components/baseComon/banner.vue
-->
<template>
  <section class="other-common-banner">
    <!-- pc -->
    <el-carousel class="banner-pc" trigger="click" arrow="never" :interval="3000" :indicator-position="typeView">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index">
        <img :src="item.imgUrl" class="banner-img">
      </el-carousel-item>
    </el-carousel>
    <!-- mb -->
    <el-carousel class="banner-mb" trigger="click" arrow="never" :interval="3000" :indicator-position="typeView">
      <el-carousel-item v-for="(item,index) in bannerListMobile" :key="index">
        <img :src="item.imgUrl" class="banner-img">
      </el-carousel-item>
    </el-carousel>
  </section>
</template>

<script>

export default {
  name: 'Banner',
  props: {
    bannerList: {
      type: Array,
      default: () => []
    },
    bannerListMobile: {
      type: Array,
      default: () => []
    },
    typeView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bannerListPc: [
        {
          imgUrl: require('../../assets/images/home/banner1.png')
        },
        {
          imgUrl: require('../../assets/images/home/banner2.jpeg'),
          linkUrl: 'https://www.uaregood.net/category.php?id=1458'
        }
      ],
      bannerListMb: [
        {
          imgUrl: require('../../assets/images/moblie/banner2.jpg')
        },
        {
          imgUrl: require('../../assets/images/moblie/banner1.jpg')
        }
      ]
    };
  },
  methods: {
  }
};
</script>
<style lang="scss" scope>
@media screen and (min-width: 1200px) {
  .other-common-banner {
    width: 100%;
    // max-width: 1920px;
    height: auto;
    min-height: 600px;
    margin: 0 auto;
    .banner-img{
      width: 100%;
      height: 100%;
      object-fit: cover ;//cover ,contain none
      cursor: pointer;
      background-size:cover
    }
  }
  .banner-mb{
    display: none;
  }
  .el-carousel__container{
    width: 100%;
    height: auto;
    min-height: 600px;
  }
}
@media screen and (min-width:993px) and (max-width: 1200px){
  .el-carousel__container{
    width: 100%;
    height: auto;
    min-height: 380px !important;
  }
  .banner-img{
    width: 100%;
    height: 400px;
    object-fit: cover !important;
    cursor: pointer;
  }
  .banner-mb{
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .other-common-banner {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    .banner-img{
      width: 100%;
      height: 200px;
    }
  }
  .banner-pc{
    display: none;
  }
  .el-carousel__container{
    width: 100%;
    height: auto;
    min-height: 200px !important;
  }
}
</style>
