<template>
  <div>
    <div class="multibanner">
      <div class="SwiperBox" @mouseenter="MouseFun('shiftIn')" @mouseleave="MouseFun('shiftOn')">
        <!-- 图片 -->
        <div class="imgBox" :style="{left:`-${leftVal}px`,transition:`${ition}s`}">
          <div v-for="(item,index) in imgList" :key="index">
            <img v-for="(items,indexs) in item" :key="indexs" :src="items.imgUrl" alt="">
          </div>
          <!-- 复制第一张放到最后,以实现无缝无线循环滚动效果 -->
          <div>
            <img v-for="(items,indexs) in imgList[0]" :key="indexs" :src="items.imgUrl" alt="">
          </div>
        </div>
        <!-- 下方指示点容器 -->
        <div class="instBox">
          <div v-for="(item,index) in imgList.length" :key="index" :class="['inst',index==imgShow?'instActv':'']" @click="instFun(index)" />
        </div>
      </div>
    </div>
    <div class="banner-mobile">
      <div class="outer-box">
        <div v-for="(items,indexs) in imgData" :key="indexs" class="inner-box">
          <img :src="items.imgUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Multibanner',
  data() {
    return {
      imgData: [
        { imgUrl: require('../../assets/images/room/icon-roomtop1.jpg') },
        { imgUrl: require('../../assets/images/room/icon-roomtop2.jpg') },
        { imgUrl: require('../../assets/images/room/icon-roomtop3.jpg') },
        { imgUrl: require('../../assets/images/room/icon-roomtop4.jpg') },
        { imgUrl: require('../../assets/images/room/icon-roomtop5.jpg') },
        { imgUrl: require('../../assets/images/room/icon-roomtop6.jpg') }
      ],
      leftVal: 0, // 轮播图盒子的偏移值
      start: null, // 自动执行下一张定的时器
      imgWidth: 1200, // 在这里填写你需要的图片宽度
      ition: 0.8, // 设置轮播图过度时间
      imgShow: 0 // 表示当前显示的图片索引
    };
  },
  computed: {
    imgList() { // 数组分割
      const arr = [];
      const num = 3; // 填写需要分割成几个一组的数量
      if (this.imgData.length) {
        for (let i = 0; i < this.imgData.length; i += num) {
          arr.push(this.imgData.slice(i, i + num));
        }
      }
      return arr;
    }
  },
  mounted() {
    this.rotation();
  },
  methods: {
    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun(type) { // 停止定时器            // 重新执行定时器
      type === 'shiftIn' ? clearTimeout(this.start) : this.rotation();
    },
    // 此为自动轮播定时器
    rotation() {
      this.start = setInterval(() => {
        this.NextFun();
      }, 3500);
    },
    // 下一张
    NextFun() {
      if (this.leftVal === (this.imgList.length - 1) * this.imgWidth) { // 判断显示的图片 是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        this.imgShow = 0;
        setTimeout(() => {
          this.ition = 0;
          this.leftVal = 0;
        }, this.ition * 1000);
      } else { // 判断显示的图片 不是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        this.imgShow++;
      }
    },
    // 点击小圆点
    instFun(index) {
      this.ition = 0.8;
      this.leftVal = index * this.imgWidth;
      this.imgShow = index;
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .multibanner{
    width: 100%;
    padding: 70px 0;
    display: flex;
    justify-content: center;
    // padding:80px 0px;
    /* 图片容器样式 */
    .SwiperBox {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      height: 330px;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
    }
    .imgBox{
      position: absolute;
      top: 0px;
      left: 0px;
      min-width: 1200px;
      height: 330px;
      display: flex;
      justify-content: space-around;
    }
    /* 图片默认样式 */
    .imgBox div {
      flex-shrink: 0;
      width: 1200px;
      height: 330px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .imgBox div img{
      width: 380px;
      height: 268px;
    }
    /* 下方指示器盒子 */
    .instBox{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      display: flex;
    }
    /* 小圆点 */
    .inst{
      width: 10px;
      height: 10px;
      border: 1px solid #e6e6e6;
      margin-right: 8px;
      background: #e6e6e6;
      border-radius: 50%;
      cursor: pointer;
    }
    .inst:last-child{
      margin-right: 0px;
    }
    .instActv{
      width: 30px;
      height: 10px;
      background: #4C9AFB;
      border-radius: 30px;
      border: 1px solid #4C9AFB;
    }
  }
  .banner-mobile{
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .multibanner{
    display: none;
  }
  .banner-mobile{
    background: #f5f7fa;
    padding: 20px 0  0 15px;
    .outer-box{
      overflow-y: hidden;
      display: flex;
      .inner-box{
        width: 1720px;
        img{
          width: 270px;
          height: 180px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
